<template>
  <export-excel type="xls" :fields="fields" :name="`${filename}.xls`" :before-generate="startDownload" :before-finish="finishDownload" :fetch="fetch">
    <mf-button text :disabled="disabled" color="marca-0-l40" :loading="exporting" :class="btnClass" label="Exportar" icon="download" @click="() => {}" />
  </export-excel>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    btnClass: {
      type: String,
      default: () => 'mx-2'
    },
    fetchFn: {
      type: Function,
      default: () => () => []
    },
    filename: {
      type: String,
      default: () => 'data_table'
    }
  },
  data: () => ({
    exporting: false
  }),
  methods: {
    startDownload() {
      this.$emit('update:exporting', true)
    },
    finishDownload() {
      this.$emit('update:exporting', false)
      this.$snackbar({ message: 'Exportação realizada com sucesso.', snackbarColor: '#2E7D32' })
    },
    async fetch() {
      const data = await this.fetchFn()
      return data
    }
  }
}
</script>
